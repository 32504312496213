import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  areaTitle = 'ASEGURAMIENTO DE CALIDAD\nANDÉN';
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  showSmartcard(userData: any) {
    this.router.navigate(['/anden/smartcard'], {state: {userData}});
  }

}
