import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FlourShipmentsService} from '../../services/flour-shipments.service';
import {TallowShipmentsService} from '../../services/tallow-shipments.service';

@Component({
  selector: 'app-freight-line-evaluation-select-shipping',
  templateUrl: './freight-line-evaluation-select-shipping.component.html',
  styles: [
  ]
})
export class FreightLineEvaluationSelectShippingComponent implements OnInit {

  loading = true;

  flourShipments = [];
  tallowShipments = [];

  constructor(
    private router: Router,
    private flourShipmentsService: FlourShipmentsService,
    private tallowShipmentsService: TallowShipmentsService,
  ) { }

  ngOnInit(): void {
    this.loading = true;

    const promises = [
      this.flourShipmentsService.getShipmentsWithNoFreighterReview(),
      this.tallowShipmentsService.getShipmentsWithNoFreighterReview()
    ];

    Promise.all(promises).then(responses => {
      this.flourShipments = responses[0]['data'];
      this.tallowShipments = responses[1]['data'];

      this.loading = false;
    });
  }

  onSelectShipping(shipment, type: string) {
    this.router.navigate(['/embarques/evaluacion-flete/confirmar'], {state: {shipment, type}});
  }

}
