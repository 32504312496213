<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info"></i> EVALUACIÓN DE SERVICIO DE LÍNEAS TRANSPORTISTAS
            </div>
            <div>
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-bold-600 font-medium-2 mb-50">
                                CLIENTE
                            </div>
                            <fieldset class="form-group position-relative">
                                <input type="text" class="form-control" disabled
                                       value="{{shipment.contract.client.name}}">
                            </fieldset>

                            <div class="text-bold-600 font-medium-2 mb-50">
                                PRODUCTO
                            </div>
                            <fieldset class="form-group position-relative">
                                <input type="text" class="form-control" disabled
                                       value="{{shipment.contract.product.name}}">
                            </fieldset>

                            <div class="text-bold-600 font-medium-2 mb-50">
                                FECHA
                            </div>
                            <fieldset class="form-group position-relative">
                                <input type="text" class="form-control" disabled
                                       value="{{shipment.date|date:'dd/MM/y'}}">
                            </fieldset>

                            <div class="text-bold-600 font-medium-2 mb-50">
                                FLETERA
                            </div>
                            <fieldset class="form-group position-relative">
                                <input type="text" class="form-control" disabled
                                       value="{{shipment.freighter.name}}">
                            </fieldset>

                            <div class="text-bold-600 font-medium-2 mb-50">
                                PLACAS TRACTO
                            </div>
                            <fieldset class="form-group position-relative">
                                <input type="text" class="form-control" disabled
                                       value="{{shipment.tractor_plate}}">
                            </fieldset>

                            <ng-container *ngIf="type === 'flour'">
                                <div class="text-bold-600 font-medium-2 mb-50">
                                    PLACAS JAULA / TOLVA
                                </div>
                                <fieldset class="form-group position-relative">
                                    <input type="text" class="form-control" disabled
                                           value="{{shipment.cage_plate}}">
                                </fieldset>
                            </ng-container>

                            <ng-container *ngIf="type === 'tallow'">
                                <div class="text-bold-600 font-medium-2 mb-50">
                                    PLACAS PIPA
                                </div>
                                <fieldset class="form-group position-relative">
                                    <input type="text" class="form-control" disabled
                                           value="{{shipment.pipe_plate}}">
                                </fieldset>
                            </ng-container>

                            <div class="text-bold-600 font-medium-2 mb-50">
                                OPERADOR
                            </div>
                            <fieldset class="form-group position-relative">
                                <input type="text" class="form-control" disabled
                                       value="{{shipment.operator_name}}">
                            </fieldset>

                            <div class="text-bold-600 font-medium-2 mb-50">
                                FOLIO
                            </div>
                            <fieldset class="form-group position-relative">
                                <input type="text" class="form-control" formControlName="folio">
                            </fieldset>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <ng-container formArrayName="points1">
                                <div *ngFor="let _ of points1Arr.controls; index as i">
                                    <ng-container [formGroupName]="i">
                                        <div class="text-bold-600 font-small-3 mb-50">
                                            {{pointsSection1[i].point}}
                                        </div>
                                        <fieldset class="form-group position-relative">
                                            <select class="form-control" formControlName="result">
                                                <option [ngValue]="null" selected disabled>Selecciona una opción...</option>
                                                <option ngValue="SI">SÍ</option>
                                                <option ngValue="NO">NO</option>
                                            </select>
                                        </fieldset>
                                        <div class="mb-2">
                                            <input formControlName="remarks" type="text" class="form-control" placeholder="Observaciones">
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title mb-2">CONDICIONES DE LA UNIDAD</h5>

                            <ng-container formArrayName="points2">
                                <div *ngFor="let _ of points2Arr.controls; index as i">
                                    <ng-container [formGroupName]="i">
                                        <div class="text-bold-600 font-small-3 mb-50">
                                            {{pointsSection2[i].point}}
                                        </div>
                                        <fieldset class="form-group position-relative">
                                            <select class="form-control" formControlName="result">
                                                <option [ngValue]="null" selected disabled>Selecciona una opción...</option>
                                                <option ngValue="ÓPTIMA">ÓPTIMA</option>
                                                <option ngValue="REGULAR">REGULAR</option>
                                                <option ngValue="DEFICIENTE">DEFICIENTE</option>
                                                <option ngValue="OTROS">OTROS</option>
                                            </select>
                                        </fieldset>
                                        <div class="mb-2">
                                            <input formControlName="remarks" type="text" class="form-control" placeholder="Observaciones">
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>

                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <ng-container formArrayName="points3">
                                <div *ngFor="let _ of points3Arr.controls; index as i">
                                    <ng-container [formGroupName]="i">
                                        <div class="text-bold-600 font-small-3 mb-50">
                                            {{pointsSection3[i].point}}
                                        </div>
                                        <fieldset class="form-group position-relative">
                                            <select class="form-control" formControlName="result">
                                                <option [ngValue]="null" selected disabled>Selecciona una opción...</option>
                                                <option ngValue="SI">SÍ</option>
                                                <option ngValue="NO">NO</option>
                                            </select>
                                        </fieldset>
                                        <div class="mb-2">
                                            <input formControlName="remarks" type="text" class="form-control" placeholder="Observaciones">
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h4 class="float-right mb-1">Fotos capturadas: {{photos.length}}</h4>
                            <div>
                                <webcam [width]="280" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                                        mirrorImage="never"
                                        [allowCameraSwitch]="true" [switchCamera]="nextWebcamObservable"
                                        [videoOptions]="videoOptions"
                                        [imageQuality]="1"
                                        (cameraSwitched)="cameraWasSwitched($event)"
                                ></webcam>
                            </div>
                            <button type="button" (click)="triggerSnapshot()" class="btn btn-block btn-primary mb-1 mt-1">
                                TOMAR FOTO
                            </button>

                            <img *ngFor="let p of photos; index as i;"
                                 [src]="p.imageAsDataUrl"
                                 width="80"
                                 class="mr-50 mb-50" alt="Foto {{i}}">
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="text-bold-600 font-medium-2 mb-50">
                                RECOMENDACIONES A LA LÍNEA
                            </div>
                            <fieldset class="form-group position-relative">
                                <textarea
                                    rows="5"
                                    style="resize: none"
                                    class="form-control" formControlName="recommendations"></textarea>
                            </fieldset>
                        </div>
                    </div>

                    <button type="submit"
                            [disabled]="form.invalid"
                            class="btn btn-lg btn-block btn-relief-success waves-effect waves-light mb-2">
                        CONFIRMAR
                    </button>
                    <button [routerLink]="['..']" type="button"
                            class="btn btn-lg btn-block btn-relief-danger waves-effect waves-light">CANCELAR
                    </button>
                </form>
                <!-- //DATOS REGISTRADOS -->
            </div>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
