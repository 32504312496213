<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> SELECCIONAR PROCEDENCIA DE MATERIA PRIMA
            </div>
            <!-- DATOS DE COCEDORESS DISPONIBLES -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- COCEDOR DISPONIBLE -->
                    <div *ngFor="let m of moves" class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-xl bg-info shadow mt-1 mb-2">
                                        <div class="avatar-content">
                                            <i class="fa fa-map-marker font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- NOMBRE COCEDORES -->
                                        <h1 class="mb-2 text-white">{{m.period.product.full_name}}</h1>
                                        <h3 class="mb-2 text-white">{{m.period.origins}}</h3>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="selectMove(m)" type="button" class="btn btn-lg btn-relief-info mr-1 mb-2 waves-effect waves-light">COMENZAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //COCEDOR DISPONIBLE -->
                </div>
            </section>
            <!-- //DATOS DE COCEDORES DISPONIBLES -->
            
        </div>
    </div>
</div>
