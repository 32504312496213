import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OriginService } from '../../services/origin.service';

@Component({
  selector: 'app-select-run-register-review',
  templateUrl: './select-run-register-review.component.html',
  styles: [
  ]
})
export class SelectRunRegisterReviewComponent implements OnInit {

  data = {
    internal: [],
    external: []
  };

  constructor(
    private originService: OriginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.originService.getOrigins().then(resp => {
      this.data = resp['data'];
    });
  }

  selectInternal(r: any) {
    const data = {
      runID: r.run_id,
      origin: r.route.route_key,
      vehicle: {
        plate: r.vehicle.license_plate,
        key: r.vehicle.vehicle_key
      }
    };
    this.navigate(data, false);
  }

  selectExternal(r: any) {
    const data = {
      runID: r.run_id,
      origin: r.origin_city,
      vehicle: {
        plate: r.license_plate,
        key: r.vehicle_key
      }
    };
    this.navigate(data, true);
  }

  navigate(route: any, isExternal: boolean) {
    const data = {route, isExternal};
    this.router.navigate(['/anden', 'evaluacion', 'confirmar'], {state: {data}});
  }

}
