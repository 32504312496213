import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlatformRoutingModule } from './platform-routing.module';
import { LoginComponent } from './components/login/login.component';
import { ScannerModule } from '../scanner/scanner.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { LayoutModule } from '../layout/layout.module';
import { RegisterReceptionChecklistComponent } from './components/register-reception-checklist/register-reception-checklist.component';
import { RegisterReviewComponent } from './components/register-review/register-review.component';
import { SelectRunRegisterReviewComponent } from './components/select-run-register-review/select-run-register-review.component';
import { SelectRunRegisterReceptionComponent } from './components/select-run-register-reception/select-run-register-reception.component';
import {NgxLoadingModule} from 'ngx-loading';


@NgModule({
  declarations: [LoginComponent, SmartcardComponent, ProcessesComponent, RegisterReceptionChecklistComponent, RegisterReviewComponent, SelectRunRegisterReviewComponent, SelectRunRegisterReceptionComponent],
  imports: [
    CommonModule,
    PlatformRoutingModule,
    ScannerModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    NgxLoadingModule
  ]
})
export class PlatformModule { }
