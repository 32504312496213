import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../login/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FreighterReviewsService {
  private url = environment.apiUrl;
  private token = '';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.token = auth.getToken();
    this.headers = new HttpHeaders({ Authorization: this.token });
  }

  getReviewPoints() {
    const headers = this.headers;
    const url = this.url + `freighter-review-points`;
    return this.http.get(url, { headers })
      .toPromise();
  }

  createReview(body: any) {
    const headers = this.headers;
    const url = this.url + `freighter-reviews`;
    return this.http.post(url, body, { headers })
      .toPromise();
  }
}
