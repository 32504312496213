import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-processes',
  templateUrl: './processes.component.html',
  styles: []
})
export class ProcessesComponent implements OnInit {

  processes = [
    { title: 'CHECKLIST DE RECEPCIÓN', link: '/anden/checklist-recepcion/procedencia' },
    { title: 'EVALUACIÓN DE MATERIA PRIMA', link: '/anden/evaluacion/procedencia' },
  ];
  constructor() { }

  ngOnInit() {
  }

}
