<div class="auth-wrapper d-flex no-block justify-content-center align-items-center"
     style="background:url(assets/images/backgrounds/auth-bg.jpg) no-repeat center center;">
    <div class="auth-box">
        <div class="logo">
            <h4>HAMISA</h4>
            <h5 class="font-medium mb-1 mt-1">{{areaTitle}}</h5>
            <span *ngIf="wrongData" class="text-center mb-3 text-danger">Datos erróneos</span>
            <span *ngIf="serverError" class="text-center mb-3 text-warning">Error del servidor</span>
        </div>
        <!-- INGRESAR A SISTEMA -->
        <div class="row">
            <div class="col-12">
                <form class="form-horizontal">
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-user"></i></span>
                        </div>
                        <input class="form-control form-control-lg" placeholder="NIP DE ACCESO"
                               [formControl]="nip" aria-label="NIP DE ACCESO"
                               autocomplete="current-password"
                               type="password" pattern="[0-9]*" inputmode="numeric">
                    </div>

                    <div class="form-group text-center">
                            <button type="button" (click)="submit()" [disabled]="loading || nip.invalid"
                                    class="btn btn-block btn-lg btn-info">INGRESAR
                            </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
