import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RawMaterialReviewService } from '../../services/raw-material-review.service';

@Component({
  selector: 'app-register-review',
  templateUrl: './register-review.component.html',
  styles: [
    'textarea {resize: none}'
  ]
})
export class RegisterReviewComponent implements OnInit {

  form: UntypedFormGroup;
  now = new Date();
  score = 0.0;
  data: any;
  loading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private rawMaterialReviewService: RawMaterialReviewService
  ) {
    if (!history.state.data) {
      router.navigate(['/anden/procesos']);
    }
    this.buildForm();
    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit(): void {
    this.data = history.state.data;
  }

  buildForm() {
    this.form = this.formBuilder.group({
      quality: [null, Validators.required],
      cleanliness: [null, Validators.required],
      layout: [null, Validators.required],
      fat_temperature: [null, Validators.required],
      bone_temperature: [null, Validators.required],
      depletion_temperature: [null, Validators.required],
      remarks: [null],
    });
  }

  updateScore() {
    this.score =
      (this.form.value.quality +
      this.form.value.cleanliness +
      this.form.value.layout) / 3;
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const body = this.form.value;
      body.run_id = this.data.route.runID;
      this.rawMaterialReviewService.registerReview(body, this.data.isExternal).then(resp => {
        this.router.navigate(['/anden']);
      });
    }
  }

  cancel() {
    this.router.navigate(['/anden/procesos']);
  }

}
