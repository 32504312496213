import { Component, OnInit } from '@angular/core';
import { GrindingMovesService } from '../../services/grinding-moves.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-flour-log-select-grinding-move',
  templateUrl: './flour-log-select-grinding-move.component.html',
  styles: [
  ]
})
export class FlourLogSelectGrindingMoveComponent implements OnInit {

  moves = [];

  constructor(
    private grindingMovesService: GrindingMovesService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.grindingMovesService.getNonLoggedGrindingMoves().then(resp => {
      this.moves = resp['data'];
    });
  }

  selectMove(move: any) {
    this.router.navigate(['/molienda/bitacora-harina/confirmar'], {state: {move}});
  }

}
