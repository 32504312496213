import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-smartcard-main',
  templateUrl: './smartcard.component.html',
  styles: []
})
export class SmartcardComponent implements OnInit {

  @Input() userData: {name: string};
  @Output() navigate = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {}

  accept() {
    this.navigate.emit(true);
  }

  back() {
    this.navigate.emit(false);
  }

}
