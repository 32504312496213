import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScannerComponent } from './components/scanner/scanner.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ScannerComponent, SmartcardComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule
  ],
  exports: [
    ScannerComponent,
    SmartcardComponent
  ]
})
export class ScannerModule { }
