import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-smartcard',
  templateUrl: './smartcard.component.html',
  styles: []
})
export class SmartcardComponent implements OnInit {

  userData: any;
  constructor(
    private router: Router
  ) {
    if (!history.state.userData) {
      router.navigate(['/anden']);
    }
  }

  ngOnInit() {
    this.userData = history.state.userData;
  }

  navigate(navigate: boolean) {
    if (navigate) {
      this.router.navigate(['/anden/procesos']);
    } else {
      this.router.navigate(['/anden']);
    }
  }

}
