import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OriginService } from '../../services/origin.service';

@Component({
  selector: 'app-select-run-register-reception',
  templateUrl: './select-run-register-reception.component.html',
  styles: [
  ]
})
export class SelectRunRegisterReceptionComponent implements OnInit {

  data = {
    internal: [],
    external: []
  };

  constructor(
    private originService: OriginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.originService.getOrigins(true).then(resp => {
      this.data = resp['data'];
    });
  }

  selectInternal(r: any) {
    const data = {
      runID: r.run_id,
      origin: r.route.route_key,
      batch: r.batch,
    };
    this._navigate(data, false);
  }

  selectExternal(r: any) {
    const data = {
      runID: r.run_id,
      origin: r.origin_city,
      batch: r.batch,
    };
    this._navigate(data, true);
  }

  private _navigate(route: any, isExternal: boolean) {
    const data = {route, isExternal};
    this.router.navigate(['/anden', 'checklist-recepcion', 'confirmar'], {state: {data}});
  }

}
