import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from './layout/layout.module';
import { LoginModule } from './login/login.module';
import { PlatformModule } from './platform/platform.module';
import { NgxLoadingModule } from 'ngx-loading';
import { GrindingModule } from './grinding/grinding.module';
import {ShippingModule} from './shipping/shipping.module';
import {WebcamModule} from 'ngx-webcam';

const routes: Routes = [
  // { path: '', redirectTo: '/descarga', pathMatch: 'full'},
  // { path: '**', redirectTo: '/descarga', pathMatch: 'full'},
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    LoginModule,
    PlatformModule,
    GrindingModule,
    ShippingModule,
    NgxLoadingModule.forRoot({fullScreenBackdrop: true}),
    WebcamModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
