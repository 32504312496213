<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> SELECCIONAR EMBARQUE DISPONIBLE
            </div>
            <!-- DATOS DE PROCESOS DISPONIBLES -->
            <section class="basic-textarea">
                <app-shipping *ngFor="let s of flourShipments"
                              [client]="s.contract.client.name"
                              [product]="s.contract.product.name"
                              [date]="s.date|date:'dd/MM/y'"
                              [freighter]="s.freighter.name"
                              (selectShipping)="onSelectShipping(s, 'flour')">
                </app-shipping>

                <app-shipping *ngFor="let s of tallowShipments"
                              [client]="s.contract.client.name"
                              [product]="s.contract.product.name"
                              [date]="s.date|date:'dd/MM/y'"
                              [freighter]="s.freighter.name"
                              (selectShipping)="onSelectShipping(s, 'tallow')">
                </app-shipping>
            </section>
            <!-- //DATOS DE PROCESO DISPONIBLE -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
