import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {MainComponent} from '../layout/main/main.component';
import {SmartcardComponent} from './components/smartcard/smartcard.component';
import {AuthGuard} from '../login/guards/auth.guard';
import {ProcessesComponent} from './components/processes/processes.component';
import {FlourVehicleChecklistComponent} from './components/flour-vehicle-checklist/flour-vehicle-checklist.component';
import {TallowVehicleChecklistComponent} from './components/tallow-vehicle-checklist-component/tallow-vehicle-checklist.component';
import {FreightLineEvaluationComponent} from './components/freight-line-evaluation/freight-line-evaluation.component';
import {FreightLineEvaluationSelectShippingComponent} from './components/freight-line-evaluation-select-shipping/freight-line-evaluation-select-shipping.component';
import {TallowVehicleChecklistSelectShippingComponent} from './components/tallow-vehicle-checklist-select-shipping/tallow-vehicle-checklist-select-shipping.component';
import {FlourVehicleChecklistSelectShippingComponent} from './components/flour-vehicle-checklist-select-shipping/flour-vehicle-checklist-select-shipping.component';

const routes: Routes = [
  {
    path: 'embarques', children:
      [
        {path: '', component: LoginComponent},
        {path: '', component: MainComponent, canActivate: [AuthGuard], children:
            [
              {path: 'smartcard', component: SmartcardComponent},
              {path: 'procesos', component: ProcessesComponent},
              {path: 'evaluacion-flete', children:
                [
                  {path: '', component: FreightLineEvaluationSelectShippingComponent},
                  {path: 'confirmar', component: FreightLineEvaluationComponent},
                ],
              },
              {path: 'checklist-harina', children:
                [
                  {path: '', component: FlourVehicleChecklistSelectShippingComponent},
                  {path: 'confirmar', component: FlourVehicleChecklistComponent}
                ],
              },
              {path: 'checklist-sebo', children:
                [
                  {path: '', component: TallowVehicleChecklistSelectShippingComponent},
                  {path: 'confirmar', component: TallowVehicleChecklistComponent}
                ]
              },
            ]
        }
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShippingRoutingModule {
}
