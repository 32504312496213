import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/login/services/auth.service';

@Component({
  selector: 'app-scanner-main',
  templateUrl: './scanner.component.html',
  styles: []
})
export class ScannerComponent implements OnInit {

  @Input() areaTitle: string;
  @Output() loginSuccess = new EventEmitter<any>();

  loading = false;
  nip = new UntypedFormControl(null, Validators.required);
  formSubmitAttempt: boolean;
  wrongData: boolean;
  serverError: boolean;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  submit() {
    if (this.nip.valid) {
      this.loading = true;
      this.authService.signInByHash(this.nip.value).then(resp => {
        const userData = {
          name: resp['full_name'],
        };
        this.loginSuccess.emit(userData);
      }).catch(err => {
        if (err.status === 401 || err.status === 404) {
          this.wrongData = true;
        } else {
          this.serverError = true;
        }
        this.loading = false;
      });
    }
  }

}
