import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GrindingRoutingModule } from './grinding-routing.module';
import { ScannerModule } from '../scanner/scanner.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { LoginComponent } from './components/login/login.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { FlourLogSelectGrindingMoveComponent } from './components/flour-log-select-grinding-move/flour-log-select-grinding-move.component';
import { FlourLogConfirmComponent } from './components/flour-log-confirm/flour-log-confirm.component';


@NgModule({
  declarations: [LoginComponent, SmartcardComponent, ProcessesComponent, FlourLogSelectGrindingMoveComponent, FlourLogConfirmComponent],
  imports: [
    CommonModule,
    GrindingRoutingModule,
    ScannerModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule
  ]
})
export class GrindingModule { }
