import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
  ]
})
export class LoginComponent implements OnInit {

  areaTitle = 'ASEGURAMIENTO DE CALIDAD\nEMBARQUES';
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  showSmartcard(userData: any) {
    this.router.navigate(['/embarques/smartcard'], {state: {userData}});
  }

}
