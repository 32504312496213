<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info"></i> SELECCIONAR PROCEDENCIA DE MATERIA PRIMA
            </div>
            <!-- DATOS DE COCEDORESS DISPONIBLES -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- COCEDOR DISPONIBLE -->
                    <div *ngFor="let i of data.internal" class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-lg bg-info shadow mb-1">
                                        <div class="avatar-content">
                                            <i class="fa fa-map-marker font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- NOMBRE COCEDORES -->
                                        <h1 class="text-white">{{i.route.route_key|uppercase}}</h1>
                                        <h4 class="text-white">{{i.vehicle.vehicle_key}}</h4>
                                    </div>
                                    <div class="text-center">
                                        <button (click)="selectInternal(i)" type="button" class="btn btn-lg btn-relief-info waves-effect waves-light">COMENZAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let e of data.external" class="col-sm-12">
                        <div class="card text-white bg-gradient-info text-center">
                            <div class="card-content">
                                <div class="card-body text-center">
                                    <div class="avatar avatar-lg bg-info shadow mt-1 mb-1">
                                        <div class="avatar-content">
                                            <i class="fa fa-map-marker font-large-1"></i>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- NOMBRE COCEDORES -->
                                        <h1 class="text-white">{{e.origin_city|uppercase}}</h1>
                                        <small>(EXTERNO)</small>
                                        <h4 class="text-white">{{e.vehicle_key}}</h4>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button (click)="selectExternal(e)" type="button" class="btn btn-lg btn-relief-info waves-effect waves-light">COMENZAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //COCEDOR DISPONIBLE -->
                </div>
            </section>
            <!-- //DATOS DE COCEDORES DISPONIBLES -->

        </div>
    </div>
</div>
