<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info mr-1"></i> REGISTRAR EVALUACIÓN DE MATERIA PRIMA
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <section class="basic-textarea">
                <div class="row">
                    <!-- DATOS REGISTRADOS -->
                    <div class="col-sm-12">
                        <form [formGroup]="form" (ngSubmit)="submit()">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">

                                        <!-- TIEMPO REGISTRADO -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                PROCEDENCIA
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control"
                                                    value="{{move.period.origins}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-map"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                TURNO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control"
                                                    value="{{move.period.shift.name}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-calendar"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                TIPO DE PRODUCTO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control"
                                                    value="{{move.period.product.full_name}} - {{move.period.product.short_name}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-industry"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                HORA DE REGISTRO
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="text" class="form-control"
                                                    value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                                <div class="form-control-position">
                                                    <i class="fa fa-clock-o"></i>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <!-- //TIEMPO REGISTRADO -->

                                        <!-- PRESIÓN DE BOMBA -->

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                COSTALES REGISTRADOS
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input class="form-control" value="{{move.obtained_sacks|number}}" disabled>
                                                <div class="form-control-position">
                                                    <i class="fa fa-th"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                KILOGRAMOS
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input class="form-control" value="{{move.kilograms|number}}" disabled>
                                                <div class="form-control-position">
                                                    <i class="fa fa-balance-scale"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                CONFIRMAR COSTALES
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input type="number" (keyup)="updateKilograms()" formControlName="sacksCount" class="form-control"
                                                    placeholder="INGRESAR CANTIDAD DE COSTALES PRODUCIDOS">
                                                <div class="form-control-position">
                                                    <i class="fa fa-th"></i>
                                                </div>
                                            </fieldset>
                                        </div>


                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                KILOGRAMOS PRODUCIDOS
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input class="form-control" value="{{kilograms|number}}" disabled>
                                                <div class="form-control-position">
                                                    <i class="fa fa-balance-scale"></i>
                                                </div>
                                            </fieldset>
                                        </div>


                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                LOTE
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input class="form-control" formControlName="batch" placeholder="210123-D01">
                                                <div class="form-control-position">
                                                    <i class="fa fa-barcode"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="text-bold-600 font-medium-2 mb-1">
                                                OBSERVACIONES
                                            </div>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <textarea formControlName="remarks" class="form-control"
                                                    placeholder="INGRESAR OBSERVACIONES"></textarea>
                                                <div class="form-control-position">
                                                    <i class="fa fa-commenting"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                    </div>
                                </div>
                            </div>
                             <button type="submit" [disabled]="form.invalid || loading"
                                class="btn btn-lg btn-block btn-relief-success mr-1 mb-3 waves-effect waves-light">CONFIRMAR</button>
                            <button (click)="cancel()" [disabled]="loading" type="button"
                                class="btn btn-lg btn-block btn-relief-danger mr-1 mb-3 waves-effect waves-light">CANCELAR</button>
                        </form>
                    </div>
                    <!-- //DATOS REGISTRADOS -->
                </div>
            </section>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
