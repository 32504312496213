import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ReceptionStatusService} from '../../services/reception-status.service';
import {ReceptionProductTypesService} from '../../services/reception-product-types.service';
import {RawMaterialReceptionService} from '../../services/raw-material-reception.service';

@Component({
  selector: 'app-register-reception-checklist',
  templateUrl: './register-reception-checklist.component.html',
  styles: [
    '.card {margin-bottom: 1rem}'
  ]
})
export class RegisterReceptionChecklistComponent implements OnInit {

  now = new Date();
  data: any;
  loading = false;

  status = [];
  types = [];
  descriptions = [];

  form: UntypedFormGroup;
  parts: UntypedFormArray;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private receptionStatusService: ReceptionStatusService,
    private receptionProductTypesService: ReceptionProductTypesService,
    private rawMaterialReceptionService: RawMaterialReceptionService
  ) {

    if (!history.state.data) {
      router.navigate(['/anden/procesos']);
    }

    this.buildForm();

    setInterval(() => this.now = new Date(), 1000);
  }

  ngOnInit(): void {
    this.loading = true;
    this.data = history.state.data;
    const getAllStatusPromise = this.receptionStatusService.getAllStatus();
    const getAllProductTypes = this.receptionProductTypesService.getAllTypes();

    Promise.all([getAllStatusPromise, getAllProductTypes]).then(response => {
      this.status = response[0]['data'];
      this.types = response[1]['data'];
      this.loading = false;
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      status: [null, Validators.required],
      parts: this.formBuilder.array([this.createPart()])
    });
    this.parts = this.form.get('parts') as UntypedFormArray;
    this.descriptions.push(this.createDescription());
  }

  createPart() {
    return this.formBuilder.group({
      product: [null, Validators.required],
      temperature: [{value: null, disabled: true}, Validators.required],
      odor: [{value: null, disabled: true}, Validators.required],
      color: [{value: null, disabled: true}, Validators.required],
      texture: [{value: null, disabled: true}, Validators.required],
      // amount: [{value: null, disabled: true}, Validators.required],
      rmeType: [{value: null, disabled: true}],
      rmeAmount: [{value: null, disabled: true}],
      vehicle: [{value: null, disabled: true}, Validators.required],
    });
  }

  createDescription() {
    return {
      odor: '',
      color: '',
      texture: '',
      temperature: '',
    };
  }

  addPart() {
    this.parts = this.form.get('parts') as UntypedFormArray;
    this.parts.push(this.createPart());
    this.descriptions.push(this.createDescription());
  }

  deletePart(i) {
    this.parts = this.form.get('parts') as UntypedFormArray;
    if (this.parts.length > 1) {
      this.parts.removeAt(i);
      this.descriptions.splice(i, 1);
    }
  }

  updateDescriptions(index) {
    const part = this.parts.at(index);

    for (const t of this.types) {
      if (t.type_id === part.get('product').value) {
        this.descriptions[index] = {
          odor: t.odor,
          color: t.color,
          texture: t.texture,
          temperature: t.temperature,
        };
        part.enable();
        break;
      }
    }
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const parts = [];
      for (let i = 0; i < this.parts.length; i++) {
        const p = this.parts.at(i);
        parts.push({
          product_id: p.value.product,
          temperature_status_id: p.value.temperature,
          color_status_id: p.value.color,
          odor_status_id: p.value.odor,
          texture_status_id: p.value.texture,
          // amount: p.value.amount,
          rme_type: p.value.rmeType,
          rme_amount: p.value.rmeAmount,
          vehicle: p.value.vehicle
        });
      }

      const data = {
        runID: this.data.route.runID,
        isExternal: this.data.isExternal,
        status_id: this.form.value.status,
        parts: JSON.stringify(parts)
      };
      this.rawMaterialReceptionService.registerReception(data).then((resp) => {
        this.router.navigate(['/anden']);
      });
    }
  }

  cancel() {
    this.router.navigate(['/anden/procesos']);
  }

}
