import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../layout/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from '../login/guards/auth.guard';
import { ProcessesComponent } from './components/processes/processes.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { RegisterReceptionChecklistComponent } from './components/register-reception-checklist/register-reception-checklist.component';
import { RegisterReviewComponent } from './components/register-review/register-review.component';
import { SelectRunRegisterReviewComponent } from './components/select-run-register-review/select-run-register-review.component';
import { SelectRunRegisterReceptionComponent } from './components/select-run-register-reception/select-run-register-reception.component';


const routes: Routes = [
  { path: 'anden', component: LoginComponent },
  { path: 'anden', component: MainComponent, children: [
    { path: 'smartcard', component: SmartcardComponent, canActivate: [AuthGuard] },
    { path: 'procesos', component: ProcessesComponent, canActivate: [AuthGuard] },
    { path: 'checklist-recepcion/procedencia', component: SelectRunRegisterReceptionComponent, canActivate: [AuthGuard] },
    { path: 'checklist-recepcion/confirmar', component: RegisterReceptionChecklistComponent, canActivate: [AuthGuard] },
    { path: 'evaluacion/procedencia', component: SelectRunRegisterReviewComponent, canActivate: [AuthGuard] },
    { path: 'evaluacion/confirmar', component: RegisterReviewComponent, canActivate: [AuthGuard] },
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlatformRoutingModule { }
