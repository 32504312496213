import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-processes',
  templateUrl: './processes.component.html',
  styles: [
  ]
})
export class ProcessesComponent implements OnInit {

  processes = [
    { title: 'CHECKLIST UNIDAD HARINA', link: '/embarques/checklist-harina' },
    { title: 'CHECKLIST UNIDAD SEBO', link: '/embarques/checklist-sebo' },
    { title: 'EVALUACIÓN DE LÍNEA TRANSPORTISTA', link: '/embarques/evaluacion-flete' },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
