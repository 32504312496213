import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShippingRoutingModule } from './shipping-routing.module';
import { LoginComponent } from './components/login/login.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { FlourVehicleChecklistComponent } from './components/flour-vehicle-checklist/flour-vehicle-checklist.component';
import {ScannerModule} from '../scanner/scanner.module';
import {LayoutModule} from '../layout/layout.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxLoadingModule} from 'ngx-loading';
import { TallowVehicleChecklistComponent } from './components/tallow-vehicle-checklist-component/tallow-vehicle-checklist.component';
import { FreightLineEvaluationComponent } from './components/freight-line-evaluation/freight-line-evaluation.component';
import { FreightLineEvaluationSelectShippingComponent } from './components/freight-line-evaluation-select-shipping/freight-line-evaluation-select-shipping.component';
import {WebcamModule} from 'ngx-webcam';
import {TallowVehicleChecklistSelectShippingComponent} from './components/tallow-vehicle-checklist-select-shipping/tallow-vehicle-checklist-select-shipping.component';
import {FlourVehicleChecklistSelectShippingComponent} from './components/flour-vehicle-checklist-select-shipping/flour-vehicle-checklist-select-shipping.component';


@NgModule({
  declarations: [LoginComponent, SmartcardComponent, ProcessesComponent, FlourVehicleChecklistComponent, TallowVehicleChecklistComponent, FreightLineEvaluationComponent, FreightLineEvaluationSelectShippingComponent, TallowVehicleChecklistSelectShippingComponent, FlourVehicleChecklistSelectShippingComponent],
  imports: [
    CommonModule,
    ShippingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ScannerModule,
    LayoutModule,
    NgxLoadingModule,
    WebcamModule
  ]
})
export class ShippingModule { }
