import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../layout/main/main.component';
import { AuthGuard } from '../login/guards/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { SmartcardComponent } from './components/smartcard/smartcard.component';
import { FlourLogSelectGrindingMoveComponent } from './components/flour-log-select-grinding-move/flour-log-select-grinding-move.component';
import { FlourLogConfirmComponent } from './components/flour-log-confirm/flour-log-confirm.component';

const routes: Routes = [
  { path: 'molienda', component: LoginComponent },
  { path: 'molienda', component: MainComponent, children: [
    { path: 'smartcard', component: SmartcardComponent, canActivate: [AuthGuard] },
    { path: 'procesos', component: ProcessesComponent, canActivate: [AuthGuard] },
    { path: 'bitacora-harina/seleccionar', component: FlourLogSelectGrindingMoveComponent, canActivate: [AuthGuard] },
    { path: 'bitacora-harina/confirmar', component: FlourLogConfirmComponent, canActivate: [AuthGuard] },
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GrindingRoutingModule { }
