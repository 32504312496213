<div>
    <div class="card text-white bg-gradient-info text-center">
        <div class="card-content">
            <div class="card-body text-center">
                <div class="avatar avatar-xl bg-info shadow mb-1">
                    <div class="avatar-content">
                        <i class="fa fa-cogs font-large-1"></i>
                    </div>
                </div>
                <div class="text-center">
                    <h1 class="text-white">{{product}}</h1>
                    <h2 class="text-white">{{client}}</h2>
                    <h3 class="text-white">{{freighter}}</h3>
                    <h4 class="mb-2 text-white">{{date}}</h4>
                </div>
                <div class="text-center mt-2">
                    <button (click)="onClick()" type="button" class="btn btn-lg btn-relief-info waves-effect waves-light">SELECCIONAR</button>
                </div>
            </div>
        </div>
    </div>
</div>
