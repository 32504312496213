import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FlourControlLogsService } from '../../services/flour-control-logs.service';

@Component({
  selector: 'app-flour-log-confirm',
  templateUrl: './flour-log-confirm.component.html',
  styles: [],
})
export class FlourLogConfirmComponent implements OnInit {

  move: any;
  form: UntypedFormGroup;
  now = new Date();
  kilograms = 0.0;
  loading = false;
  batch = '';

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private flourControlLogsService: FlourControlLogsService
  ) {
    if (!history.state.move) {
      router.navigate(['/molienda/procesos']);
    }

    setInterval(() => this.now = new Date(), 1000);
    this.buildForm();
  }

  ngOnInit(): void {
    this.loading = true;
    this.flourControlLogsService.getBatch().then(response => {
      this.batch = response['data'].batch;
      this.form.patchValue({batch: this.batch});
      this.loading = false;
    });
    this.move = history.state.move;
  }

  buildForm() {
    this.form = this.formBuilder.group({
      sacksCount: [null, Validators.required],
      remarks: [null],
      batch: [null]
    });
  }

  updateKilograms() {
    const sacks = this.form.get('sacksCount');
    if (sacks.valid) {
      this.kilograms = parseInt(sacks.value) * 25;
    } else {
      this.kilograms = 0.0;
    }
  }

  submit() {
    if (!/^[0-9]{6}-D[0-9]{2}$/.test(this.form.value.batch)) {
      alert('El lote debe cumplir el formato DDMMAA-DXX');
      return;
    }

    if (this.form.valid) {
      this.loading = true;
      const body = {
        ...this.form.value,
        grinderMovementID: this.move.movement_id,
      };
      this.flourControlLogsService.createFlourLog(body).then(resp => {
        this.router.navigate(['/molienda']);
      });
    }
  }

  cancel() {
    this.router.navigate(['/molienda/procesos']);
  }

}
