import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-processes',
  templateUrl: './processes.component.html',
  styles: [
  ]
})
export class ProcessesComponent implements OnInit {

  processes = [
    { title: 'BITÁCORA DE CONTROL - HARINA', link: '/molienda/bitacora-harina/seleccionar' },
  ];
  constructor() { }

  ngOnInit() {
  }

}
