<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info"></i> REGISTRAR EVALUACIÓN DE MATERIA PRIMA
            </div>
            <!-- DATOS DE OPERACIÓN -->
            <!-- DATOS REGISTRADOS -->
            <form [formGroup]="form" (ngSubmit)="submit()">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body">

                            <!-- TIEMPO REGISTRADO -->
                            <div class="text-bold-600 font-medium-2 mb-1">
                                RUTA DE PROCEDENCIA
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="text" class="form-control"
                                       value="{{data.route.origin|uppercase}}" disabled="">
                                <div class="form-control-position">
                                    <i class="fa fa-clock-o"></i>
                                </div>
                            </fieldset>

                            <div class="text-bold-600 font-medium-2 mb-1">
                                VEHÍCULO
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="text" class="form-control"
                                       value="{{data.route.vehicle.key|uppercase}}"
                                       disabled="">
                                <div class="form-control-position">
                                    <i class="fa fa-clock-o"></i>
                                </div>
                            </fieldset>

                            <div class="text-bold-600 font-medium-2 mb-1">
                                HORA DE REGISTRO
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="text" class="form-control"
                                       value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                <div class="form-control-position">
                                    <i class="fa fa-clock-o"></i>
                                </div>
                            </fieldset>
                            <!-- //TIEMPO REGISTRADO -->

                            <!-- PRESIÓN DE BOMBA -->

                            <div class="text-bold-600 font-medium-2 mb-1">
                                CALIDAD DE MATERIA PRIMA
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="number" (keyup)="updateScore()" formControlName="quality"
                                       class="form-control"
                                       placeholder="INGRESAR CALIFICACIÓN">
                                <div class="form-control-position">
                                    <i class="fa fa-check-circle"></i>
                                </div>
                            </fieldset>

                            <div class="text-bold-600 font-medium-2 mb-1">
                                LIMPIEZA
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="number" (keyup)="updateScore()" formControlName="cleanliness"
                                       class="form-control"
                                       placeholder="INGRESAR CALIFICACIÓN">
                                <div class="form-control-position">
                                    <i class="fa fa-thumbs-o-up"></i>
                                </div>
                            </fieldset>


                            <div class="text-bold-600 font-medium-2 mb-1">
                                SEPARACIÓN Y ORDEN DEL PRODUCTO
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="number" (keyup)="updateScore()" formControlName="layout"
                                       class="form-control"
                                       placeholder="INGRESAR CALIFICACIÓN">
                                <div class="form-control-position">
                                    <i class="fa fa-th"></i>
                                </div>
                            </fieldset>


                            <div class="text-bold-600 font-medium-2 mb-1">
                                CALIFICACIÓN
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="number" class="form-control" disabled value="{{score}}">
                                <div class="form-control-position">
                                    <i class="fa fa-star"></i>
                                </div>
                            </fieldset>


                            <div class="text-bold-600 font-medium-2 mb-1">
                                TEMPERATURA GRASA
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="number" formControlName="fat_temperature" class="form-control"
                                       placeholder="INGRESAR TEMPERATURA (ºC)">
                                <div class="form-control-position">
                                    <i class="fa fa-thermometer-half"></i>
                                </div>
                            </fieldset>

                            <div class="text-bold-600 font-medium-2 mb-1">
                                TEMPERATURA HUESO
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="number" formControlName="bone_temperature" class="form-control"
                                       placeholder="INGRESAR TEMPERATURA (ºC)">
                                <div class="form-control-position">
                                    <i class="fa fa-thermometer-half"></i>
                                </div>
                            </fieldset>


                            <div class="text-bold-600 font-medium-2 mb-1">
                                TEMPERATURA ESQUILMO
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="number" formControlName="depletion_temperature"
                                       class="form-control"
                                       placeholder="INGRESAR TEMPERATURA (ºC)">
                                <div class="form-control-position">
                                    <i class="fa fa-thermometer-half"></i>
                                </div>
                            </fieldset>


                            <div class="text-bold-600 font-medium-2 mb-1">
                                OBSERVACIONES
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <textarea formControlName="remarks" class="form-control"
                                          placeholder="INGRESAR OBSERVACIONES"></textarea>
                                <div class="form-control-position">
                                    <i class="fa fa-commenting"></i>
                                </div>
                            </fieldset>

                        </div>
                    </div>
                </div>
                <button type="submit" [disabled]="form.invalid"
                        class="btn btn-lg btn-block btn-relief-success waves-effect waves-light">
                    CONFIRMAR
                </button>
                <button (click)="cancel()" type="button"
                        class="btn btn-lg btn-block btn-relief-danger waves-effect waves-light">
                    CANCELAR
                </button>
            </form>
            <!-- //DATOS REGISTRADOS -->
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
