import { Component, OnInit } from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {WebcamImage, WebcamUtil} from 'ngx-webcam';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {TallowVehicleChecklistService} from '../../services/tallow-vehicle-checklist.service';

@Component({
  selector: 'app-tallow-vehicle-checklist',
  templateUrl: './tallow-vehicle-checklist.component.html',
  styles: [
  ]
})
export class TallowVehicleChecklistComponent implements OnInit {
  loading = false;

  form: UntypedFormGroup;
  points1Arr = new UntypedFormArray([]);
  points2Arr = new UntypedFormArray([]);
  photos: WebcamImage[] = [];

  shipment: any;
  points = [];
  pointsSection1 = [];
  pointsSection2 = [];

  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public facingMode = 'environment';
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  constructor(
    private router: Router,
    private tallowVehicleChecklistService: TallowVehicleChecklistService,
    private formBuilder: UntypedFormBuilder,
  ) {
    if (!history.state.shipment) {
      router.navigate(['/embarques/checklist-harina']);
    }

    this._buildForm();
  }

  ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });

    this.shipment = history.state.shipment;
    this.loading = true;
    this.tallowVehicleChecklistService.getChecklistPoints().then(response => {
      this.points = response['data'];

      this._setFormData();
      this.loading = false;
    });
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;

      const answers = [...this.points1Arr.getRawValue(), ...this.points2Arr.getRawValue()];

      const images = [];
      for (const photo of this.photos) {
        images.push(photo.imageAsBase64);
      }

      const body = {
        checklist: {
          shipmentId: this.shipment.shipment_id,
          securitySeals: this.form.value.securitySeals,
          remarks: this.form.value.remarks,
          status: this.form.value.status,
        },
        answers,
        images
      };

      this.tallowVehicleChecklistService.createChecklist(body).then(() => {
        this.router.navigate(['/embarques']);
      }).catch(err => {
        console.log(err);
        this.loading = false;
      });
    }
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.photos.push(webcamImage);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }

    return result;
  }

  private _buildForm() {
    this.form = this.formBuilder.group({
      points1: this.formBuilder.array([]),
      points2: this.formBuilder.array([]),
      securitySeals: [null],
      remarks: [null],
      status: [null, Validators.required]
    });

    this.points1Arr = this.form.get('points1') as UntypedFormArray;
    this.points2Arr = this.form.get('points2') as UntypedFormArray;
  }

  private _setFormData() {
    this.pointsSection1 = this.points.filter((p) => p.section === 1);
    this.pointsSection2 = this.points.filter((p) => p.section === 2);

    for (const p of this.pointsSection1) {
      this.points1Arr.push(this.formBuilder.group({
        pointId: [p.point_id],
        result: [false],
        remarks: [null]
      }));
    }

    for (const p of this.pointsSection2) {
      this.points2Arr.push(this.formBuilder.group({
        pointId: [p.point_id],
        result: [false],
        remarks: [null]
      }));
    }

  }

}
