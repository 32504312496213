import {Component, Input, OnInit, Output} from '@angular/core';
import {EventEmitter} from '@angular/core';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styles: [
  ]
})
export class ShippingComponent implements OnInit {

  @Input() product: string;
  @Input() client: string;
  @Input() freighter: string;
  @Input() date: string;
  @Output() selectShipping = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.selectShipping.emit(true);
  }

}
