<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <div class="alert alert-primary">
                <i class="feather icon-info"></i> RECEPCIÓN DE MATERIA PRIMA
            </div>
            <div>
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="card">
                        <div class="card-body">

                            <div class="text-bold-600 font-medium-2 mb-50">
                                HORA DE REGISTRO
                            </div>
                            <fieldset class="form-group position-relative has-icon-left">
                                <input type="text" class="form-control"
                                       value="{{now|date:'hh:mm:ss a'}}" disabled="">
                                <div class="form-control-position">
                                    <i class="fa fa-clock-o"></i>
                                </div>
                            </fieldset>

                            <div class="text-bold-600 font-medium-2 mb-50">
                                PROCEDENCIA (No. de Lote)
                            </div>
                            <fieldset class="position-relative has-icon-left">
                                <input type="text" class="form-control" disabled
                                       value="{{data.route.batch}}">
                                <div class="form-control-position">
                                    <i class="fa fa-map-marker"></i>
                                </div>
                            </fieldset>

                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">

                            <div class="text-bold-600 font-medium-2 mb-1">
                                DISTRIBUCIÓN DE MATERIA PRIMA
                            </div>

                            <div formArrayName="parts">
                                <fieldset *ngFor="let p of form.get('parts')['controls']; let i = index;"
                                          [formGroupName]="i"
                                          class="form-group position-relative has-icon-left">
                                    <hr>

                                    <div class="row">
                                        <div class="col">
                                            <div class="text-bold-600 font-medium-1 mb-1">
                                                DISTRIBUCIÓN {{i + 1}}
                                            </div>
                                        </div>
                                        <div class="col">
                                            <button (click)="deletePart(i)" type="button"
                                                    [disabled]="parts.length === 1"
                                                    class="btn btn-sm btn-danger float-right">
                                                <i class="fa fa-minus-circle"></i>
                                                ELIMINAR
                                            </button>
                                        </div>
                                    </div>

                                    <div class="row mb-2">
                                        <div class="col-sm-12">
                                            <select formControlName="product" (change)="updateDescriptions(i)"
                                                    class="select2 form-control">
                                                <option [ngValue]="null" selected disabled>
                                                    Tipo de materia prima...
                                                </option>
                                                <option *ngFor="let t of types" [ngValue]="t.type_id">
                                                    {{t.name}}</option>
                                            </select>
                                            <div class="form-control-position">
                                                <i class="fa fa-database" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-2">
                                        <div class="col-sm-12">
                                            <div class="text-bold-600 font-small-3">
                                                TEMPERATURA
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="font-small-3 mt-25 mb-25">
                                                {{descriptions[i].temperature}}
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <select formControlName="temperature" class="form-control">
                                                <option [ngValue]="null" selected disabled>Selecciona un
                                                    status...
                                                </option>
                                                <option *ngFor="let s of status" [ngValue]="s.status_id">
                                                    {{s.full_name}}</option>
                                            </select>
                                            <div class="form-control-position">
                                                <i class="fa fa-thermometer-half" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-2">
                                        <div class="col-sm-12">
                                            <div class="text-bold-600 font-small-3">
                                                OLOR
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="font-small-3 mt-25 mb-25">
                                                {{descriptions[i].odor}}
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <select formControlName="odor" class="form-control">
                                                <option [ngValue]="null" selected disabled>
                                                    Selecciona un status...
                                                </option>
                                                <option *ngFor="let s of status" [ngValue]="s.status_id">
                                                    {{s.full_name}}</option>
                                            </select>
                                            <div class="form-control-position">
                                                <i class="fa fa-pagelines" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-2">
                                        <div class="col-sm-12">
                                            <div class="text-bold-600 font-small-3">
                                                COLOR
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="font-small-3 mt-25 mb-25">
                                                {{descriptions[i].color}}
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <select formControlName="color" class="form-control">
                                                <option [ngValue]="null" selected disabled>Selecciona un
                                                    status...
                                                </option>
                                                <option *ngFor="let s of status" [ngValue]="s.status_id">
                                                    {{s.full_name}}</option>
                                            </select>
                                            <div class="form-control-position">
                                                <i class="fa fa-eyedropper" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-2">
                                        <div class="col-sm-12">
                                            <div class="text-bold-600 font-small-3">
                                                TEXTURA
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="font-small-3 mt-25 mb-25">
                                                {{descriptions[i].texture}}
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <select formControlName="texture" class="form-control">
                                                <option [ngValue]="null" selected disabled>Selecciona un
                                                    status...
                                                </option>
                                                <option *ngFor="let s of status" [ngValue]="s.status_id">
                                                    {{s.full_name}}</option>
                                            </select>
                                            <div class="form-control-position">
                                                <i class="fa fa-hand-o-down" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

<!--                                    <div class="row mb-2">-->
<!--                                        <div class="col-sm-12">-->
<!--                                            <div class="text-bold-600 font-small-3">-->
<!--                                                CANTIDAD (KG)-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-sm-12">-->
<!--                                            <input formControlName="amount" type="number"-->
<!--                                                   class="form-control"-->
<!--                                                   placeholder="INGRESAR CANTIDAD DE MATERIA PRIMA">-->
<!--                                            <div class="form-control-position">-->
<!--                                                <i class="fa fa-balance-scale"></i>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->

                                    <div class="row mb-2">
                                        <div class="col-sm-12">
                                            <div class="text-bold-600 font-small-3">
                                                VEHICULO
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <input formControlName="vehicle" type="text"
                                                   class="form-control"
                                                   placeholder="INGRESAR CLAVE DE VEHÍCULO">
                                            <div class="form-control-position">
                                                <i class="fa fa-truck"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-2">
                                        <div class="col-sm-12">
                                            <div class="text-bold-600 font-small-3">
                                                TIPO DE RME
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <input formControlName="rmeType" type="text"
                                                   class="form-control"
                                                   placeholder="INGRESAR TIPO DE RESIDUO">
                                            <div class="form-control-position">
                                                <i class="fa fa-trash-o"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="text-bold-600 font-small-3">
                                                PESO DE RME (KG)
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <input formControlName="rmeAmount" type="number"
                                                   class="form-control"
                                                   placeholder="INGRESAR CANTIDAD DE RESIDUOS">
                                            <div class="form-control-position">
                                                <i class="fa fa-balance-scale"></i>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <hr>
                                <button (click)="addPart()" type="button"
                                        class="btn btn-lg btn-block btn-relief-info waves-effect waves-light">
                                    <i class="fa fa-plus-circle"></i> AÑADIR DISTRIBUCIÓN
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="text-bold-600 font-medium-2 mb-1">
                                STATUS DE LA RECEPCIÓN
                            </div>

                            <fieldset class="position-relative has-icon-left">
                                <select formControlName="status" class="form-control">
                                    <option [ngValue]="null" selected disabled>Selecciona una opción...
                                    </option>
                                    <option *ngFor="let s of status" [ngValue]="s.status_id">{{s.full_name}}
                                    </option>
                                </select>
                                <div class="form-control-position">
                                    <i class="fa fa-th-list" aria-hidden="true"></i>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <button type="submit" [disabled]="form.invalid"
                            class="btn btn-lg btn-block btn-relief-success waves-effect waves-light">CONFIRMAR
                    </button>
                    <button (click)="cancel()" type="button"
                            class="btn btn-lg btn-block btn-relief-danger waves-effect waves-light">CANCELAR
                    </button>
                </form>
                <!-- //DATOS REGISTRADOS -->
            </div>
            <!-- //DATOS DE OPERACIÓN -->

        </div>
    </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
