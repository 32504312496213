import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MainComponent } from './main/main.component';
import { SimplePageComponent } from './simple-page/simple-page.component';
import { ProcessComponent } from './components/process/process.component';
import { ShippingComponent } from './components/shipping/shipping.component';



@NgModule({
  declarations: [FooterComponent, NavbarComponent, MainComponent, SimplePageComponent, ProcessComponent, ShippingComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([])
  ],
  exports: [
    MainComponent,
    SimplePageComponent,
    ProcessComponent,
    ShippingComponent
  ]
})
export class LayoutModule { }
