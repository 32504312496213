import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FlourShipmentsService} from '../../services/flour-shipments.service';

@Component({
  selector: 'app-flour-vehicle-checklist-select-shipping',
  templateUrl: './flour-vehicle-checklist-select-shipping.component.html',
  styles: [
  ]
})
export class FlourVehicleChecklistSelectShippingComponent implements OnInit {

  loading = true;

  shipments = [];

  constructor(
    private router: Router,
    private flourShipmentsService: FlourShipmentsService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.flourShipmentsService.getShipmentsWithNoChecklist().then(response => {
      this.shipments = response['data'];
      this.loading = false;
    });
  }

  onSelectShipping(shipment) {
    this.router.navigate(['/embarques/checklist-harina/confirmar'], {state: {shipment}});
  }

}
